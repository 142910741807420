import React from 'react';
import albania from './img/albania.webp';
import MetaTags from './components/MetaTags';
import ReservationForm from './components/ReservationForm';

function Reservation(props: LanguageDetail) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.reservation ?? ''}
        url={window.location.href}
        image={albania} />
      <h1>{ props.reservation }</h1>
      <p>Reservation form is under construction, please reach us using e-mail.</p>
      {/* <p>
        Create the form for the reservation of the accomodation respecting the following. 
        Use typescript, react, material/ui for styling and the form result will be sent to make.com
        The form includes title (combo), firstname, lastname, address, country (auto hint if possible).
        For the fields Date from and Date to use a calendar component from material ui if possible.
        It must not be possible to select the date which is already booked in the shared google calendar
        so the booked dates must be disabled in the date from and date to fields.
        The shared google calendar is shown bellow the reservation form via iframe.
        The form is responsive, two columns on desktops and tablets, single column on mobile.
        There are buttons for sending and reseting the form.
        The code must notify the user that was successfully sent or show an error 
        if the data to make.com were not sent for some reason.
      </p> */}

      {/* <ReservationForm /> */}
      {/* <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FPrague&showPrint=0&src=OGE2YjZkYWIwZjIxOGFiMzlmZjE3OGZlYzYyY2I3NjE1YTE5ZTk3ZTk0YWZiMDFkOWU4NWJmZjJjOTk2NmEwNkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F4511E" width="100%" height="600"></iframe> */}
    </>
  );
}

export default Reservation;
