import React from 'react';
import albania from './img/albania.webp';
import MetaTags from './components/MetaTags';

function Gallery(props: LanguageDetail) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.gallery}
        url={window.location.href}
        image={albania} />
      <h1>{ props.gallery }</h1>
      <p>TBD</p>
    </>
  );
}

export default Gallery;
