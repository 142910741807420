// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  text-align: start;
  margin: auto !important;
}
body h1 {
  text-align: center;
}
body fieldset {
  border: 0 !important;
}
body a {
  color: darkorange;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,iBAAA;EACA,uBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,mBAAA;AADJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
