import { createTheme } from "@mui/material/styles";

// Define a sophisticated dark theme palette
const Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Light blue
      contrastText: "#ffffff", // White for contrast
    },
    secondary: {
      main: "#f48fb1", // Light pink
      contrastText: "#000000", // Black for contrast
    },
    error: {
      main: "#f44336", // Red for errors
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ffa726", // Amber
      contrastText: "#000000",
    },
    info: {
      main: "#29b6f6", // Cyan
      contrastText: "#ffffff",
    },
    success: {
      main: "#66bb6a", // Green
      contrastText: "#ffffff",
    },
    background: {
      default: "#121212", // Dark gray background
      paper: "#1e1e1e", // Slightly lighter gray for cards and containers
    },
    text: {
      primary: "#ffffff", // White text for primary content
      secondary: "#b0bec5", // Light gray for secondary text
      disabled: "#757575", // Gray for disabled text
    },
    divider: "#424242", // Dark gray for dividers
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: "2.125rem",
      fontWeight: 600,
      color: "#ffffff",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
      color: "#ffffff",
    },
    body1: {
      fontSize: "1rem",
      color: "#b0bec5",
    },
    body2: {
      fontSize: "0.875rem",
      color: "#b0bec5",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Avoid all caps
          borderRadius: "8px", // Softer corners
        },
        containedPrimary: {
          backgroundColor: "#90caf9",
          color: "#121212",
          "&:hover": {
            backgroundColor: "#64b5f6", // Slightly darker on hover
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e", // Paper-specific background
          color: "#ffffff", // Text color for paper
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e",
          "& .MuiInputBase-root": {
            color: "#ffffff", // Text color for input
          },
          "& .MuiInputLabel-root": {
            color: "#b0bec5", // Label color
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#424242",
            },
            "&:hover fieldset": {
              borderColor: "#90caf9",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#90caf9",
            },
          },
        },
      },
    },
  },
});

export default Theme;
