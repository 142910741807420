import React from 'react';
import Parser from 'html-react-parser';
import albania from './img/albania.webp';
import MetaTags from './components/MetaTags';
import GoogleMapComponent from './components/GoogleMapComponent';

function Contact(props: LanguageDetail) {
  const user = 'stay';
  const domain = 'lovebania.com';
  const mail = `${user}@${domain}`;

  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.contact}
        url={window.location.href}
        image={albania} />
      <h1>{ Parser(props.contact) }</h1>
      <p>
        {`${props.contactInfo} `}
        <a href={`mailto:${mail}`}>
          {user}@{domain}
        </a>
      </p>
      <GoogleMapComponent />
    </>
  );
}

export default Contact;
