import React, { useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

export interface Translations {
  en: LanguageDetail,
  it: LanguageDetail,
  cs: LanguageDetail,
  de: LanguageDetail,
  sq: LanguageDetail,
  sr: LanguageDetail,
  mk: LanguageDetail,
}

export const supportedLanguages = ['en','it','de','cs','sq','mk','sr'];

interface LanguageSelectorProps extends Translations {
    onChange: (language: string) => void;
    selectedDetail: LanguageDetail;
}

function LanguageSelector(props: LanguageSelectorProps) {
  const [language, setLanguage] = useState(props.selectedDetail.code);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
    props.onChange(event.target.value);
  };

  const renderFlag = (languageDetail: LanguageDetail) => (
  <>
    <span className={`fi fi-${languageDetail.iconCode}`}></span>
  </>);

  return (
    <>
    <span style={{ marginLeft: 'auto', marginRight: '5px'}}>{props.selectedDetail.language} </span>
    <Select
      value={language}
      onChange={handleChange}
      variant="outlined"
    >
      <MenuItem value={props.en.code}>
        {renderFlag(props.en)}
      </MenuItem>
      <MenuItem value={props.sq.code}>
        {renderFlag(props.sq)}
      </MenuItem>
      <MenuItem value={props.mk.code}>
        {renderFlag(props.mk)}
      </MenuItem>
      <MenuItem value={props.sr.code}>
        {renderFlag(props.sr)}
      </MenuItem>
      <MenuItem value={props.de.code}>
        {renderFlag(props.de)}
      </MenuItem>
      <MenuItem value={props.it.code}>
        {renderFlag(props.it)}
      </MenuItem>
      <MenuItem value={props.cs.code}>
        {renderFlag(props.cs)}
      </MenuItem>
    </Select>
    </>
  );
}

export default LanguageSelector;